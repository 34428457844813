<template>
  <div
    id="app"
    theme="man"
    class="main"
    :key="this.$store.state.darkmode"
    v-bind:class="{ darkmode: this.$store.state.darkmode }"
  >
    <top-header
      @toggle_darkmode='toggle_darkmode'
      @handleLogout='handleLogout'
      @handleLogin='handleLogin'
    />
    <b-container class="view">
      <router-view></router-view>
    </b-container>
    <bottom-footer />
  </div>
</template>

<script>
import TopHeader from './components/top-header';
import BottomFooter from './components/bottom-footer';
import { Auth } from 'aws-amplify'

export default {
  name: "App",
  components: {
    'top-header': TopHeader,
    'bottom-footer': BottomFooter
  },
  async mounted() {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      !this.$store.state.initDarkmode
    ) {
      this.toggle_darkmode('initial_setup');
    }
    await this.page_init();

    let path = localStorage.getItem('path');
    if(path) {
      localStorage.removeItem('path');
      this.$router.push(path)
    }
  },
  async updated() {
    await this.page_init();
  },
  methods: {
    page_init: async function () {
      this.update_darkmode();
      this.update_svg_files();
      await this.refresh_token();
      await this.authenticate();
    },
    authenticate: async function () {
      try {
        this.$store.commit('setUser', (await Auth.currentSession()).getIdToken().payload)
        this.userHasAuthenticated(true);
      } catch (e) {
        if ( e.match(/(No current user|Error: Cannot retrieve a new session\. Please authenticate\.)/) == false) {
          console.log(e)
          alert(e);
        }
        this.userHasAuthenticated(false);
        this.$store.commit('clearUser')
      }
      this.$store.commit('setAdmin')
    },
    refresh_token: async function () {
      if ( this.$store.state.authenticated && (this.$store.getters.getTokenExpirationTime < Date.now()) ) {
        this.handleLogin();
      }
    },
    toggle_darkmode: function (event) {
      console.log(event);
      if ( event == 'initial_setup' ) {
        this.$store.commit('initialDarkmode')
      }
      this.$store.commit('toggleDarkmode')
      return this.$store.state.darkmode;
    },
    update_darkmode: function () {
      if (this.$store.state.darkmode){
        document.body.classList.add('darkmode')
      } else {
        document.body.classList.remove('darkmode')
      }
    },
    update_svg_files: function () {
      // setting class attributes to svg files with the class 'icon'
      this.$el.getElementsByClassName("icon").forEach((element) => {
        element.classList.add("svg");
        element.classList.add(element.id);
        element.children.forEach((child) => {
          child.classList.add("child");
          child.classList.add(element.id);
          if (element.classList.contains('darkmode')) {
            child.classList.add('darkmode')
          }
        });
      });
    },
    userHasAuthenticated: function (authenticated) {
      this.$store.commit('setAuthentication', authenticated);
    },
    handleLogout: async function () {
      await Auth.signOut();
      this.authenticate()
      this.$router.push("/").catch(failure => {
        if (this.$router.isNavigationFailure(failure, this.$router.NavigationFailureType.duplicated)) {
          // Do nothing, since we already are at the targets location.
        }
      })
    },
    handleLogin: async function () {
      await Auth.federatedSignIn(
        {
          'customProvider': 'MANAzureAD',
          'token': 'TOKEN'
        }
      )  // final solution
      // await Auth.federatedSignIn()  // for using local Users
      this.authenticate()
    }
  },
};
</script>

<style>
:root {
  --top-header-height: 35px;
  background-color: inherit;
  height: 100%;
}

body {
  background-color: var(--groupui-man-color-silver-100);
  color: var(--groupui-man-color-anthracite-800-primary);
  height: 100%;
}

body.darkmode {
  background-color: var(--groupui-man-color-anthracite-600);
  color: var(--groupui-man-color-silver-50);
}

#app {
  font-family: GroupUi-MAN, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--groupui-man-color-silver-100);
  color: var(--groupui-man-color-anthracite-800-primary);
  height: 100%;
}
#app.darkmode {
  background-color: var(--groupui-man-color-anthracite-600);
  color: var(--groupui-man-color-silver-50);
}

.view {
  margin-top: calc(2*var(--top-header-height));
  padding-bottom: 50px;
}

.darkmode {
  color: var(--groupui-man-color-silver-50);
}
</style>
