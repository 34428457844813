import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports';

import './plugins/bootstrap-vue';
import { applyPolyfills, defineCustomElements } from '@community/group-ui/loader';

Vue.config.productionTip = false

// Bind the group-ui elements to the window object
applyPolyfills().then(() => {
  defineCustomElements();
});

Amplify.configure(aws_exports);

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
