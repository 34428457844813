import { Auth } from 'aws-amplify';

export default {
    "aws_project_region": 'eu-central-1',
    "aws_cognito_region": 'eu-central-1',
    "aws_user_pools_id": 'eu-central-1_LRIlCrNDM',
    "aws_user_pools_web_client_id": '5t0vheo0nc146ti0l0bpvorf3k',
    "aws_cognito_identity_pool_id": 'eu-central-1:cc2489e6-2221-4967-95d2-384d0c10f2fc',
    "aws_appsync_graphqlEndpoint": 'https://izfzxbvrrfdldk2lkdkcxzmvvm.appsync-api.eu-central-1.amazonaws.com/graphql',
    "aws_appsync_region": 'eu-central-1',
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    Auth: {
        mandatorySignIn: true,
        region: 'eu-central-1',
        identityPoolId: 'eu-central-1:cc2489e6-2221-4967-95d2-384d0c10f2fc',
        userPoolId: 'eu-central-1_LRIlCrNDM',
        userPoolWebClientId: '5t0vheo0nc146ti0l0bpvorf3k',
        oauth: {
            domain: 'auth.portal.ccc-dev.cloudapp.man',
            scope: ['openid', 'email', 'profile'],// ['phone', 'email', 'profile', 'openid','aws.cognito.signin.user.admin'],
            redirectSignIn : 'https://portal.ccc-dev.cloudapp.man',
            redirectSignOut : 'https://portal.ccc-dev.cloudapp.man',
            responseType: 'token',
        }
    },
    API: {
        endpoints: [
            {
                name: 'ssp-appsync',
                endpoint: 'https://izfzxbvrrfdldk2lkdkcxzmvvm.appsync-api.eu-central-1.amazonaws.com/graphql'
            }
        ],
        graphql_headers: async () => ({
            'Authorization': (await Auth.currentSession()).getIdToken().getJwtToken()
        })
    },
    Analytics: {
        AWSPinpoint: {
            appId: 'a5835499b5354b208be409d4bdbb3d88',
            region: 'eu-central-1',
            mandatorySignIn: false,
        }
    }
}
