import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/Home.vue";
import store from './store';

Vue.use(VueRouter);


function authenticatedRoute(to, from, next) {
  if(!store.state.authenticated){
    next('/');
  } else {
    next();
  }
}

function AdminRoute(to, from, next) {
  if(store.state.authenticated && (store.state.isAdmin || store.state.isManager)) {
    next();
  } else {
    next('/');
  }
}

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/accounts",
    name: "accounts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("./views/Accounts.vue"),
    beforeEnter: authenticatedRoute
  },
  {
    path: "/user",
    name: "user",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("./views/User.vue"),
    beforeEnter: authenticatedRoute
  },
  {
    path: "/admin",
    name: "admin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import('./views/Admin.vue'),
    beforeEnter: AdminRoute
  },
  {
    path: '/:pathMatch(.*)*',
    beforeEnter(to, from, next) {
      next('/')
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
