<template>
  <div class="home">
    <groupui-headline heading="h1">This is the MAN AWS Self Service Portal</groupui-headline>
    <div v-if="this.$store.state.authenticated">
      <groupui-headline
        heading="h3"
      >
        Welcome  {{this.$store.getters.getUsername}}
      </groupui-headline>
      <groupui-headline
        heading="h4"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        You currently have following Groups:  {{this.$store.state.groups}}
      </groupui-headline>
      <groupui-headline
        heading="h4"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        To See more About Your AWS User Please follow to the <router-link to="/user">User View</router-link>
      </groupui-headline>
      <groupui-headline
        heading="h4"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        To See more About Your AWS Accounts Please follow to the <router-link to="/accounts">Accounts View</router-link>
      </groupui-headline>
      <groupui-headline
        v-if="this.$store.state.isAdmin"
        heading="h4"
        :class="{ darkmode: this.$store.state.darkmode }"
      >
        To See all AWS Accounts and Users Please follow to the <router-link to="/admin">Admin View</router-link>
      </groupui-headline>
    </div>
    <div v-if="!this.$store.state.authenticated">
      <groupui-headline heading="h4" weight="bold" serif="false">
        Please Authenticate to see more!
      </groupui-headline>
    </div>
    <groupui-headline heading="h4" weight="bold" serif="false">
      More Information about the AWS Cloud at MAN can be found in our <a href="https://ccc.fiobc.cloudapp.man/">Cloud Guidelines</a>
    </groupui-headline>
  </div>
</template>

<script>

import Analytics from '@aws-amplify/analytics';

export default {
  name: 'Home',
  async created() {
    Analytics.record('Access_home_page');
  },
};
</script>

<style scoped>
.home {
  height: 100%;
}

a {
  color: var(--groupui-man-color-red-500-primary);
}

a:hover {
  color: var(--groupui-man-color-red-800)
}

</style>