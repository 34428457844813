<template>
  <b-container>
    <groupui-row flexible>
      <groupui-col>
        <b-navbar
          v-bind:class="{ darkmode: this.$store.state.darkmode }"
          toggleable="lg"
          fixed="top"
          class="top"
        >
          <b-navbar-brand to="/">
            <man-logo
              class="icon"
              id="man-logo"
              v-bind:class="{ darkmode: this.$store.state.darkmode }"
            />
            <groupui-headline
              heading="h5"
              v-bind:class="{ darkmode: this.$store.state.darkmode }"
            >
              AWS Self Service Portal
            </groupui-headline>
          </b-navbar-brand>
        </b-navbar>
      </groupui-col>
      <groupui-col>
        <b-navbar
          v-bind:class='{ darkmode: this.$store.state.darkmode }'
          fixed="top"
          toggleable="sm"
          class="bottom"
        >
          <b-navbar-toggle target="nav-collapse" lx></b-navbar-toggle>
          <b-collapse
            id="nav-collapse"
            is-nav
            v-bind:class="{ darkmode: this.$store.state.darkmode }"
            lx
          >
            <b-navbar-nav>
              <b-nav-item to="/" v-bind:class="{ darkmode: this.$store.state.darkmode }" class="no-padding">
                <groupui-text
                  weight="bold"
                  v-bind:class="{ darkmode: this.$store.state.darkmode }"
                >
                  Home
                </groupui-text>
              </b-nav-item>
              <b-nav-item to="/accounts" v-bind:class="{ darkmode: this.$store.state.darkmode }" class="no-padding">
                <groupui-text
                  weight="bold"
                  v-bind:class="{ darkmode: this.$store.state.darkmode }"
                  v-if="this.$store.state.authenticated"
                >
                  Accounts
                </groupui-text>
              </b-nav-item>
              <b-nav-item to="/user" v-bind:class="{ darkmode: this.$store.state.darkmode }" class="no-padding">
                <groupui-text
                  weight="bold"
                  v-bind:class="{ darkmode: this.$store.state.darkmode }"
                  v-if="this.$store.state.authenticated"
                >
                  User
                </groupui-text>
              </b-nav-item>
              <b-nav-item to="/admin" v-bind:class="{ darkmode: this.$store.state.darkmode }" class="no-padding">
                <groupui-text
                  weight="bold"
                  v-bind:class="{ darkmode: this.$store.state.darkmode }"
                  v-if="(this.$store.state.isAdmin || this.$store.state.isManager)"
                >
                  Admin
                </groupui-text>
              </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto" right>
              <b-nav-item-dropdown
                v-bind:class="{ darkmode: this.$store.state.darkmode }"
                class="no-padding"
                v-if="this.$store.state.authenticated"
              >
                <!-- Using 'button-content' slot -->
                <template #button-content class="no-padding"
                  v-bind:class='{ darkmode: $store.state.darkmode }'
                  >
                  <groupui-text
                    weight='bold'
                    style='display: inline'
                    v-bind:class='{ darkmode: $store.state.darkmode }'
                  >
                    {{$store.getters.getEmail}}
                  </groupui-text>
                </template>
                <b-dropdown-item
                  v-on:click="$emit('handleLogout')"
                >
                  <groupui-text weight="bold">Sign Out</groupui-text>
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <groupui-button
                size='s'
                v-if="!this.$store.state.authenticated"
                v-on:click="$emit('handleLogin')"
              >
                Login
              </groupui-button>
            </b-navbar-nav>
          </b-collapse>
          <div v-on:click="$emit('toggle_darkmode', 'toggle-darkmode')">
            <sun
              width="24px"
              height="24px"
              id="sun"
              class="icon"
              v-bind:class="{ darkmode: this.$store.state.darkmode }"
              v-show="this.$store.state.darkmode"
            />
            <moon
              width="24px"
              height="24px"
              id="moon"
              class="icon"
              v-show='!this.$store.state.darkmode'
              v-bind:class='{ darkmode: this.$store.state.darkmode }'
            />
          </div>
          <man-logo-header height="70px" class="icon" id="man-logo-header" />
        </b-navbar>
      </groupui-col>
    </groupui-row>
  </b-container>
</template>

<script>
export default {
  name: 'TopHeaders',
  components: {
    'man-logo': require('../assets/logo-man.svg'),
    'man-logo-header': require('../assets/man-logo-header.svg'),
    sun: require('../assets/sun.svg'),
    moon: require('../assets/moon.svg'),
  },
};
</script>

<style>
li.no-padding .nav-link {
  padding-top: 0px;
  padding-bottom: 0px;
}
.darkmode .dropdown-toggle::after{
  color: var(--groupui-man-color-silver-50);
}
</style>

<style scoped>
.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: var(--groupui-man-color-white);
  height: var(--top-header-height);
}

.navbar.darkmode {
  background-color: var(--groupui-man-color-anthracite-700);
}

.navbar-collapse {
  background-color: var(--groupui-man-color-white);
}

.navbar-collapse.darkmode {
  background-color: var(--groupui-man-color-anthracite-700);
}

.bottom {
  height: var(--top-header-height);
  margin-top: var(--top-header-height);
  padding-right: 140px;
}

a {
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropdown-menu {
  background-color: var(--groupui-man-color-anthracite-700);
  padding-top: 0px;
  padding-bottom: 0px;
}

.dropdown-toggle::after {
  color: inherit
}

.man-logo-header {
  position: fixed;
  top: 0px;
  right: 10px;
  margin-top: 30px;
  height: 40px;
}

.svg.man-logo {
  transform: scale(2);
  transform-origin: left;
  margin-right: 25px;
  float: left;
  position: relative;
}

.man-logo.darkmode {
  fill: var(--groupui-man-color-silver-50);
}

.sun {
  position: fixed;
  top: 7px;
  right: 90px;
  margin-top: 35px;
  fill: var(--groupui-man-color-anthracite-800-primary);
}

.sun.darkmode {
  fill: var(--groupui-man-color-anthracite-50);
}

.moon {
  position: fixed;
  top: 7px;
  right: 90px;
  margin-top: 35px;
  fill: var(--groupui-man-color-anthracite-800-primary);
}

.moon.darkmode {
  fill: var(--groupui-man-color-anthracite-50);
}

@media only screen and (min-width: 475px) {
  .sun {
    margin-top: 0px;
    right: 140px;
  }
  .moon {
    margin-top: 0px;
    right: 140px;
  }
  .man-logo-header {
    margin-top: 0px;
    height: 70px;
    display: block;
  }
}
</style>
