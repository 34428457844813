export const getMyUser = /* GraphQL */ `
  query MyQuery {
    getMyUser {
      accessKeys: access_keys {
        accessKey: access_key
        createDate: create_date
        lastUsedDate: last_used_date
        status
      }
      createDate: create_date
      mfa
      userARN: user_arn
      accounts {
        accountId: account_id
        description
        level
        roles
      }
    }
  }
`;

export const listAllUsers = /* GraphQL */ `
query MyQuery {
  listAllUsers {
    user_arn
    accounts {
      account_id
      description
      roles
      level
    }
    account_ids
    create_date
    email
    mfa
  }
}
`;

export const listAllAccounts = /* GraphQL */ `
  query MyQuery {
    listAllAccounts {
        account {
          accountId: account_id
          createDate: account_creation_date
          description: account_description
          name: account_name
          rootEmail: account_root_email
          stage: account_stage
          status: account_status
          accountType: account_type
          accountAlias: account_alias
          businessService: business_service
          businessUnit: business_unit
          masterAccount: master_account
          pspElement: psp_element
          contacts {
            applicationOwner: application_owner {
              email
            }
            controlling {
              email
            }
            serviceOwner: service_owner {
              email
            }
          }
          budgetSettings: budget_settings {
            limit: budget_limit
            name: budget_name
          }
          dnsZones: dns_zones {
            zoneName: zone_name
            hostedZoneId: hosted_zone_id
            createDate: creation_date
            zoneDetails: zone_details {
              delegationSet: DelegationSet {
                nameServers: NameServers
              }
            }
          }
          costs: account_costs {
            date
            blendedCosts: blended_costs
          }
          networks {
            cidr
            region
            vpcId: vpc_id
            vpcType: vpc_type
            createDate: creation_date
          }
          serviceUsers: service_users {
            arn
            userName: user_name
            comment
            createDate: creation_date
            createProposer: creation_proposer
          }
        }
      }
    }
`;

export const listMyAccounts = /* GraphQL */ `
  query MyQuery {
    listMyAccounts {
      accounts {
        description
        level
        account {
          accountId: account_id
          createDate: account_creation_date
          description: account_description
          name: account_name
          stage: account_stage
          status: account_status
          accountType: account_type
          accountAlias: account_alias
          businessService: business_service
          businessUnit: business_unit
          masterAccount: master_account
          pspElement: psp_element
          contacts {
            applicationOwner: application_owner {
              email
            }
            controlling {
              email
            }
            serviceOwner: service_owner {
              email
            }
          }
          dnsZones: dns_zones {
            zoneName: zone_name
            hostedZoneId: hosted_zone_id
            createDate: creation_date
            zoneDetails: zone_details {
              delegationSet: DelegationSet {
                nameServers: NameServers
              }
            }
          }
          networks {
            cidr
            region
            vpcId: vpc_id
            vpcType: vpc_type
            createDate: creation_date
          }
          budgetSettings: budget_settings {
            limit: budget_limit
            name: budget_name
          }
          costs: account_costs {
            date
            blendedCosts: blended_costs
          }
          serviceUsers: service_users {
            arn
            userName: user_name
            comment
            createDate: creation_date
            createProposer: creation_proposer
          }
        }
      }
      email
    }
  }
`;
