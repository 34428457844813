import Vue from "vue";
import Vuex from 'vuex'
import { API, graphqlOperation } from "aws-amplify";
import { getMyUser, listAllUsers, listAllAccounts, listMyAccounts } from "@/graphql/queries";

Vue.use(Vuex);

const store = new Vuex.Store(
    {
      state: {
        authenticated: false,
        user: {},
        groups: [],
        isAdmin: false,
        isManager: false,
        darkmode: false,
        initDarkmode: false,
        myAWSUser: {},
        allUsers: [],
        allAccounts: {},
        myAccounts: [],
      },
      actions: {
        async getMyUserData(context) {
          try {
            console.log("retriving user data");
            try {
              var accounts_appsync_response = await API.graphql(
                graphqlOperation(getMyUser),
              );
              context.commit('setMyAWSUser', accounts_appsync_response.data.getMyUser)
            }
            catch (error){
              context.commit('setMyAWSUser', {})
            }
          } catch (error) {
            console.log("Error loading user Data...", error);
          }
        },
        async listAllUsers(context) {
          try {
            console.log("retriving all users data");
            try {
              var list_all_users_appsync_response = await API.graphql(
                graphqlOperation(listAllUsers),
              );
              context.commit('setAllUsers', list_all_users_appsync_response.data.listAllUsers)
            }
            catch (error){
              context.commit('setAllUsers', [])
            }
          }
          catch (error) {
            console.log("Error loading Data...", error);
          }
        },
        async listAllAccounts(context) {
          try {
            console.log("retriving all accounts data");
            try {
              var list_all_accounts_appsync_response = await API.graphql(
                graphqlOperation(listAllAccounts),
              );
              context.commit('setAllAccounts', list_all_accounts_appsync_response.data.listAllAccounts)
            }
            catch (error){
              context.commit('setAllAccounts', {})
            }
          }
          catch (error) {
            console.log("Error loading Data...", error);
          }
        },
        async listMyAccounts(context) {
          try {
            console.log("retriving all user accounts data");
            try {
              var list_my_accounts_appsync_response = await API.graphql(
                graphqlOperation(listMyAccounts),
              );
              context.commit('setMyAccounts', list_my_accounts_appsync_response.data.listMyAccounts.accounts)
            }
            catch (error) {
              console.log('GOT AN ERROR! ' + error)
              context.commit('setMyAccounts', [])
            }
          }
          catch (error) {
            console.log("Error loading Data...", error);
          }
        },
      },
      mutations: {
        setAuthentication(state, status) {
          state.authenticated = status;
        },
        setUser(state, user) {
          state.user = user
          state.groups = user['cognito:groups']
        },
        clearUser(state) {
          state.user = {}
          state.groups = []
        },
        setAdmin(state) {
          state.isManager = state.groups.includes('FioManager');
          state.isAdmin = state.groups.includes('CloudAdmin');
        },
        toggleDarkmode(state) {
          state.darkmode = !state.darkmode
        },
        initialDarkmode(state) {
          state.initDarkmode = true
        },
        setMyAWSUser(state, AWSUser) {
          state.myAWSUser = AWSUser
        },
        setAllUsers(state, users) {
          state.allUsers = users
        },
        setAllAccounts(state, accounts){
          state.allAccounts = accounts
        },
        setMyAccounts(state, accounts) {
          state.myAccounts = accounts
        }
      },
      getters: {
        getUsername(state) {
          return state.user.name
        },
        getEmail(state) {
          return state.user.preferred_username
        },
        getUser(state) {
          return state.user
        },
        getMyAWSUser(state) {
          return state.myAWSUser
        },
        getMyAWSAccounts(state) {
          return state.myAWSUser.accounts
        },
        getAllUsers(state) {
          return state.allUsers
        },
        getAllAccounts(state) {
          return state.allAccounts
        },
        getMyAccounts(state) {
          return state.myAccounts
        },
        getTokenExpirationTime(state) {
          return state.user.exp * 1000
        }
      }
    }
  )

export default store;
