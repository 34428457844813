<template>
  <groupui-grid-row
    class="row footer-mandatory-container"
    v-bind:class="{ darkmode: this.$store.state.darkmode }"
  >
    <groupui-grid-col>
      <groupui-text
        v-bind:class="{ darkmode: this.$store.state.darkmode }"
        class="copyright"
      >
        &copy; MAN 2021
      </groupui-text>
    </groupui-grid-col>
    <groupui-grid-col class="footer-mandatory-links">
      <groupui-link
        href="https://www.man.eu/de/en/general/imprint.html"
        class="link"
      >
        Imprint
        </groupui-link>
      <groupui-link
        href="https://www.man.eu/de/en/general/data-protection-routing.html"
        class="link"
      >
        Data Protection
      </groupui-link>
    </groupui-grid-col>
  </groupui-grid-row>
</template>

<script>
export default {
  name: "BottomFooter",
};
</script>

<style scoped>
.footer-mandatory-container {
  height: 40px;
  width: 100%;
  position: fixed;
  bottom: 0;
  margin-right: 0;
  margin-left: 0;
  background-color: var(--groupui-man-color-white);
  padding-top: 9px;
  margin-top: 10px;
}

.footer-mandatory-container.darkmode {
  background-color: var(--groupui-man-color-anthracite-800-primary);
}

.footer-mandatory-container groupui-link {
  font-weight: bold;
}

groupui-text.copyright {
  padding-left: 0.5em;
  padding-right: 1.3em;
}

.footer-mandatory-links {
  position: absolute;
  right: 15px;
}

.footer-mandatory-links groupui-link {
  clear: none;
  float: none;
  display: inline-block;
  margin-top: 0;
  margin-right: 16px;
}

.footer-mandatory-links groupui-link:after {
  position: absolute;
  content: "|";
  margin-left: 6px;
  font-weight: normal;
}

.footer-mandatory-links groupui-link:last-child {
  margin-right: auto;
}

.footer-mandatory-links groupui-link:last-child:after {
  display: none;
}

.footer-mandatory-links groupui-link:active:before,
.footer-mandatory-links groupui-link:focus:before {
  color: var(--groupui-man-color-anthracite-800-primary);
}
/* } */
</style>
